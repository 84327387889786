const nav = document.querySelector('.nav');
const navItems = nav.querySelectorAll('.nav-item');
const activeItemClassName = 'is-active';
let currentNavItem = null;

navItems.forEach(navItem => {
  navItem.addEventListener('click', event => {
    event.preventDefault();
    const sectionId = navItem.getAttribute('href');

    if(currentNavItem) currentNavItem.classList.remove(activeItemClassName);
    navItem.classList.add(activeItemClassName);
    currentNavItem = navItem;
    
    scrollToSection(sectionId);
    nav.classList.remove('is-open');
    if(navIsOpen) navIsOpen = false;
  })
})

function scrollToSection(sectionId){
  document.querySelector(sectionId).scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}